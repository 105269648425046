import { RecursivePartial, VoidFn } from "../../types/types";
import merge from 'lodash.merge';
import { initialState, State } from "./state";

class Store {
  protected eventListeners: VoidFn[] = [];

  private _prevState = initialState;

  private _state = initialState;

  get state() {
    return this._state;
  }

  updateState = (nextState: RecursivePartial<State>) => {
    this._prevState = this._state;
    this._state = merge(this.state, nextState);
    this.triggerEvent();
  }

  addEventListener = (callback: VoidFn) => {
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter(cb => cb !== callback);
    }
  }

  private triggerEvent = () => {
    this.eventListeners.forEach(cb => cb());
  }
}

export const store = new Store();

