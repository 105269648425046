import { Optional } from "../../types/types";

export const initialState = {
  ui: {
    showBtn: false,
    color: '#FF0000',
    theme: {
      color: '#00FF00'
    }
  },
  exampleOfOptionalString: undefined as Optional<string>,
}

export type State = typeof initialState;