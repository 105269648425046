export type Env = 'alpha' | 'bravo' | 'charlie' | 'dev' | 'prod' | 'local';
export const env = (process.env.REACT_APP_STAGE ?? process.env.STAGE ?? 'local') as Env; // undefined is local dev

const amplitudeKeys: Record<Env, string> = {
  prod: '8b677fca77a3203906f5df6db8dbf453',
  dev: 'f3ab3a60a48e6203f2148a4846760702',
  alpha: 'f3ab3a60a48e6203f2148a4846760702',
  bravo: 'f3ab3a60a48e6203f2148a4846760702',
  charlie: 'f3ab3a60a48e6203f2148a4846760702',
  local: '',
};

export const amplitudeKey = amplitudeKeys[env];
export const sentryDNS = 'https://a2d8ecf97f946a095b315efd7c377541@o4505230490861568.ingest.us.sentry.io/4508759165829120';

