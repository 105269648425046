import { useEffect, useState } from "react";
import { store } from "./Store";

export const useStore = () => {
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    const callback = () => {
      setRenderCount(renderCount + 1);
    };
    return store.addEventListener(callback);
  }, [renderCount]);

  return {
    state: store.state,
    updateState: store.updateState,
  }
}