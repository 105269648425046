import {PrivyProvider, usePrivy, User, getAccessToken } from '@privy-io/react-auth';
import { useEffect, useState } from 'react';

const privyClientId = 'client-WY5gJuEw4zQi2M5zpHevUyDKgd5B5VZAHVWBB4psV6AhD';

export const OGPWidgetWrapper = () => {
  return (
    // Wrap any components that will use the Privy SDK with the PrivyProvider – for example, in your `app` or `index` file
    <PrivyProvider appId='cm6yx6eem01d911vhc28mw2ni'>
      <OGPWidget />
    </PrivyProvider>
  );
}

const OGPWidget = () => {
  const { ready, authenticated, user, login, logout } = usePrivy();

  const [_, lastOgpUpdate] = useState(0);

  const [accessToken, setAccessToken] = useState('');

  const foo = () => {
    console.log('do privy login');
    login();
  }

  // Update component from the class
  useEffect(() => {
    // ogp.connectComponent((ts: number) => {
    //   lastOgpUpdate(ts);
    // })
    ogp.setPrivyLogin(foo);
  }, []);

  useEffect(() => {
    if (user) {
      ogp.setPrivyUser(user);
    }
    getAccessToken().then(res => {
      setAccessToken(res ?? '');
    })
  }, [user]);


  return (
    <div>
      <p>AccessToken</p>
      {accessToken && (
        <textarea value={accessToken}></textarea>
      )}
      <button onClick={login}>Login</button>
    </div>
  );
}

interface IframeMessage {
  action: 'string';
  data: Record<string, any>;
}

class OGPWidgetController {

  private state = {
    origin: '',
    gameId: '',
  }

  ready() {
    return false;
  }

  private componentCallback?: (timestamp: number) => void;

  private privyFns = {
    login: (p: any) => {},
  } satisfies Record<string, (p: any) => void>;

  private privyUser?: User;

  constructor() {
    window.addEventListener("message", (event) => {
      if (!this.state.origin) {
        this.state.origin = event.origin;
      } else {
        // if (this.state.origin !== event.origin) {
        //   console.error(`Invalid origin.`);
        //   return;
        // }
      }
      
      console.log("Received in iframe:", event.data);

      this.handleWidgetActions(event.data);
    });
  }

  public setPrivyLogin = (fn: (param: any) => void) => {
    this.privyFns.login = fn;
  }

  public connectComponent = (fn: (timestamp: number) => void) => {
    if (!this.componentCallback) {
      this.componentCallback = fn;
    }
  }

  public setPrivyUser = (user: User) => {
    this.privyUser = user;
  }

  private updateComponent = () => {
    console.log('OGPWidget', this.state);
    if (this.componentCallback) {
      this.componentCallback(Date.now());
    }
  }

  private handleWidgetActions = (msg: IframeMessage) => {
    console.log('handleWidgetActions', {msg});
    const handler = this.actionHandlers[msg.action];
    if (!handler) {
      console.warn(`No handler found for action '${msg.action}'`);
      return;
    }
    handler(msg.data);
  }

  private init = (data: IframeMessage['data']) => {
    if (!data.gameId) {
      console.error(`Init called without 'gameId'`);
      return;
    }
    this.state.gameId = data.gameId;
    this.updateComponent();
  }

  private waitForPrivyLogin = async(retries = 0) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!this.privyUser) {
          if (retries > 10) {
            return reject('Privy Login Failed');
          }
          return resolve(this.waitForPrivyLogin(++retries));
        }
      }, 1000);
    })
  }

  private sendPoints = async(data: IframeMessage['data']) => {
    if (!this.state.gameId) {
      console.warn(`App has not been initialised. Have you forgotten to call 'init'?`);
      return;
    }
    if (data.points === undefined) {
      console.error(`Points must be specified.`);
      return;
    }

    if (!this.privyUser) {
      this.privyFns.login({});
    }
    this.updateComponent();
    try {
      await this.waitForPrivyLogin();
    } catch (e) {

    } 
    this.updateComponent();
  }


  private actionHandlers: Record<string, (data: IframeMessage['data']) => void> = {
    init: this.init,
    sendPoints: this.sendPoints,
  }
}

const ogp = new OGPWidgetController();
